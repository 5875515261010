import { ExternalLinkButton } from "./buttons";

export const uploaderUrl =
  "https://orderbot-uploader-releases.s3.us-west-2.amazonaws.com/Orderbot+Uploader.exe";

export const DownloadUploaderButton = () => (
  <ExternalLinkButton href={uploaderUrl}>
    Download the uploader
  </ExternalLinkButton>
);
